import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
// import PageParticles from "../components/PageParticles"
export default ({ data }: any) => {
  return (
    <>
      <Layout pageData={data.wpPage} />
      {/* <PageParticles /> */}
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      status
      language {
        code
        locale
      }
      template {
        templateName
      }
      translations {
        language {
          code
          homeUrl
          id
          locale
          name
          slug
        }
        link
      }
      seo {
        metaRobotsNofollow
        metaKeywords
        metaDesc
        schema {
          raw
        }
        opengraphModifiedTime
        opengraphType
        opengraphSiteName
        opengraphTitle
        opengraphPublisher
        opengraphImage {
          width
          height
          guid
          mimeType
        }
        title
        opengraphUrl
      }
      blocks {
        attributesJSON
        name
        innerBlocks {
          attributesJSON
        }
      }
      featuredImage {
        node {
          id
          publicUrl
          gatsbyImage
        }
      }
      wpChildren {
        nodes {
          ... on WpPage {
            id
            uri
            title
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            id
            uri
            title
            wpChildren {
              nodes {
                ... on WpPage {
                  id
                  title
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`
